<template>
  <div class="spinner-mask" transition="spinner" id="load" v-if="load" style="z-index:99999;">
    <div class="spinner-wrapper text-center">
      <div class="loader">Loading...</div>
    </div>
  </div>
</template>

<script>
import './../css/bootstrap/css/bootstrap.min.css'
// import './../css/bootstrap4.css'
import './../css/bootstrap-extend.min.css'
import './../css/site.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue2-datepicker/index.css'
import 'vue-touch-keyboard/dist/vue-touch-keyboard.css'

import { mapMutations } from 'vuex'

export default {
  name: 'loadLoad',
  computed: {
    load: function () {
      return this.$store.state.load
    }
  },
  watch: {
    '$store.state.aviso' (er) {
      this.SET_LOAD()
      let i
      if (er.data.error.message) {
        if (er.data.error.message.error) {
          if (Array.isArray(er.data.error.message)) {
            let i = er.data.error.message.error[0].toString().replaceAll(' ', '_').split('.')
            switch (i.length) {
              case 2:
                this.$swal(this.$t('_.' + i[1].replace(/\s/g, '_')), this.$t(i[0]), 'error')
                break
              case 3:
                this.$swal(this.$t('_.' + i[1].replace(/\s/g, '_')), this.$t(i[0]).replace(':valor', i[2]), 'error')
                break
              case 4:
                this.$swal(this.$t('_.' + i[2].replace(/\s/g, '_')), this.$t(i[0]).replace(':valor', i[3]), 'error')
                break
              default:
                this.$swal('', this.$t(i[0]), 'error')
            }
          } else {
            this.$swal('', this.$t(er.data.error.message.error), 'error')
          }
        } else {
          if (parseInt(er.data.error.message)) {
            if (Array.isArray(er.data.error.message)) {
              i = er.data.error.message[0].toString().replaceAll(' ', '_').split('.')
            } else {
              i = er.data.error.message.toString().replaceAll(' ', '_').split('.')
            }
            switch (i.length) {
              case 2:
                this.$swal(this.$t('_.' + i[1].replace(/\s/g, '_')), this.$t(i[0]), 'error')
                break
              case 3:
                this.$swal(this.$t('_.' + i[1].replace(/\s/g, '_')), this.$t(i[0]).replace(':valor', i[2]), 'error')
                break
              case 4:
                this.$swal(this.$t('_.' + i[2].replace(/\s/g, '_')), this.$t(i[0]).replace(':valor', i[3]), 'error')
                break
              default:
                this.$swal('', this.$t(i[0]), 'error')
            }
          } else {
            this.$swal('', this.$t(er.data.error.message), 'error')
          }
        }
      } else {
        i = er.data.error[0].toString().replaceAll(' ', '_').split('.')
        switch (i.length) {
          case 2:
            this.$swal(this.$t('_.' + i[1].replace(/\s/g, '_')), this.$t(i[0]), 'error')
            break
          case 3:
            this.$swal(this.$t('_.' + i[1].replace(/\s/g, '_')), this.$t(i[0]).replace(':valor', i[2]), 'error')
            break
          case 4:
            this.$swal(this.$t('_.' + i[2].replace(/\s/g, '_')), this.$t(i[0]).replace(':valor', i[3]), 'error')
            break
          default:
            this.$swal('', this.$t(i[0]), 'error')
        }
      }
    },
    '$store.state.toast' (val) {
      if (val.msg) {
        /*
        this.$refs.toast.showToast(val.msg, {
          theme: val.type || 'success',
          timeLife: val.time || 3000
        })
        */
        this.$swal({
          position: 'top-end',
          icon: val.type || 'success',
          title: val.msg,
          showConfirmButton: false,
          timer: 4000,
          toast: true,
          stopKeydownPropagation: false
        })
        if (!val.load) {
          this.SET_LOAD()
        }
      }
    },
    '$store.state.scrollBottom' (val) {
      if (val) {
        this._.delay(() => {
          window.scrollTo(0, document.documentElement.scrollTop + 50)
        }, 150)
        this.SET_SCROLLBOTTOM(false)
      }
    }
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_SCROLLBOTTOM'
    ])
  }
}
</script>
